import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 46",
  week: "Semana 7",
  month: "abr",
  day: "28",
  monthNumber: "04",
  date: "2020-04-28",
  path: "/cronologia/semana-07#dia-28-abr",
};
const Day46 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={"2020-04-28"} />
        <ModLethalityEu fecha={"2020-04-28"} numPaises={8} />
        <ModCovidHighlight>
          Rusia sobrepasa los 87.000 casos detectados, con un incremento del
          7,7% diario
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.706 nuevos positivos y 301 personas fallecidas.
          Permanecen hospitalizadas 114.959 personas, 878 más que el día
          anterior, y 1.673 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.437, lo que supone un aumento de 65 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,3% y la tasa de recuperados del 44,2%.
        </ModText>
        <ModText>
          El Gobierno ha aprobado hoy un <strong>Plan de desescalada</strong>{" "}
          gradual, asimétrico y coordinado con las comunidades autónomas, que
          también será susceptible de adaptaciones en función de la evolución de
          los datos epidemiológicos y del impacto de las medidas adoptadas.
        </ModText>
        <ModImage
          src="/images/svg/28_abr_ruedaprensa.svg"
          alt="rueda prensa comunicado consejo de ministros"
        />
        <ModText>
          El objetivo fundamental del Plan es conseguir que, manteniendo como
          referencia la protección de la salud pública, se recupere
          paulatinamente la vida cotidiana y la actividad económica, minimizando
          el riesgo y{" "}
          <strong>
            evitando que las capacidades del Sistema Nacional de Salud se puedan
            volver a desbordar
          </strong>
          .
        </ModText>
        <ModText>
          Para ello, se han establecido los principales parámetros e
          instrumentos para la adaptación del conjunto de la sociedad a la nueva
          normalidad, con las máximas garantías de seguridad.
        </ModText>
        <ModImage src="/images/svg/28_abr_hospitalifema.svg" alt="hospital ifema" />
        <ModText>
          La <strong>Comunidad de Madrid</strong> ha realizado una inversión de
          casi 19 millones de euros en equipamiento y mantenimiento del hospital
          temporal para pacientes de COVID-19 que comenzó a funcionar el pasado
          21 de marzo en IFEMA.{" "}
          <strong>
            El Hospital ha superado los 3.800 ingresos y ha dado más de 3.600
            altas
          </strong>
          .
        </ModText>
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/resumenes/Documents/2020/PlanTransicionNuevaNormalidad.pdf"
            name="Plan de transición a una nueva normalidad"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/resumenes/Documents/2020/28042020_Anexo%20I%20PANEL%20DE%20INDICADORES.pdf"
            name="Anexo I: Panel de indicadores integral"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/resumenes/Documents/2020/28042020_Anexo%20II%20FASES.pdf"
            name="
            Anexo II: Previsión orientativa para el levantamiento de las limitaciones"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/resumenes/Documents/2020/28042020_Anexo%20III%20CRONOGRAMA.pdf"
            name="Anexo III: Cronograma orientantivo"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day46;
